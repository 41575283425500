
      export default {
  "possibleTypes": {
    "AutoplayMedia": [
      "Episode"
    ],
    "CampaignPagePanel": [
      "ChannelPanel",
      "MediaPanel",
      "PackageDetailsPanel",
      "PackagesPanel",
      "PagePanel",
      "SportEventPanel",
      "UpsellPanel"
    ],
    "CampaignPageTopPanel": [
      "PackageTopPanel",
      "UpsellPanel"
    ],
    "CancellationOffer": [
      "CancellationOfferBinding",
      "CancellationOfferDowngrade",
      "CancellationOfferVoucher"
    ],
    "CdpPanel": [
      "ClipsPanel"
    ],
    "CheckoutPagePanel": [
      "MediaPanel",
      "PagePanel",
      "SportEventPanel",
      "ThemePanel"
    ],
    "ClipParent": [
      "ClipParentMovieLink",
      "ClipParentSeriesLink"
    ],
    "ContinueWatchingMediaItem": [
      "ContinueWatchingEpisodeItem",
      "ContinueWatchingMovieItem",
      "ContinueWatchingSportEventItem"
    ],
    "EndScreenMainRecommendation": [
      "Autoplay",
      "EndScreenUpcomingMedia"
    ],
    "EndScreenMediaRecommendation": [
      "EndScreenRecommendedEpisode",
      "EndScreenRecommendedMovie",
      "EndScreenRecommendedSeries",
      "EndScreenRecommendedSportEvent"
    ],
    "EndScreenUpcomingMediaItem": [
      "Movie",
      "UpcomingEpisode"
    ],
    "EpgMediaReference": [
      "EpgItemEpisodeReference",
      "EpgItemMovieReference",
      "EpgItemSportEventReference"
    ],
    "Favorite": [
      "FavoriteClipItem",
      "FavoriteEpisodeItem",
      "FavoriteMovieItem",
      "FavoriteSeriesItem"
    ],
    "LinkTarget": [
      "PageLink",
      "StaticLink"
    ],
    "ListSearchResultItem": [
      "Clip",
      "Movie",
      "PageReference",
      "Series",
      "Short",
      "SportEvent"
    ],
    "LivePanelItem": [
      "LivePanelChannelItem",
      "LivePanelEpisodeItem",
      "LivePanelMovieItem",
      "LivePanelSportEventItem"
    ],
    "MediaByMetadataId": [
      "Channel",
      "Clip",
      "Episode",
      "Movie",
      "Series",
      "Short",
      "SportEvent"
    ],
    "MediaIndexItem": [
      "MediaIndexMovieItem",
      "MediaIndexSeriesItem"
    ],
    "MediaPanelItem": [
      "MediaPanelMovieItem",
      "MediaPanelSeriesItem"
    ],
    "MediaRecommendation": [
      "RecommendedClip",
      "RecommendedMovie",
      "RecommendedSeries"
    ],
    "NextContent": [
      "Episode",
      "UpcomingEpisode"
    ],
    "PageListContent": [
      "PageReferenceItem",
      "StaticPageItem"
    ],
    "PagePanelItem": [
      "PagePanelPageItem"
    ],
    "Panel": [
      "ChannelPanel",
      "ClipsPanel",
      "ContinueWatchingPanel",
      "EpisodesPanel",
      "LivePanel",
      "MediaPanel",
      "MultiSinglePanel",
      "PackagesPanel",
      "PagePanel",
      "ShortsPanel",
      "SinglePanel",
      "SportEventPanel",
      "ThemePanel"
    ],
    "PaymentMethodDetails": [
      "CardDetails",
      "KlarnaDetails"
    ],
    "PollSearchResultItem": [
      "EliminationPoll",
      "SurveyPoll"
    ],
    "PopularMediaItem": [
      "PopularMediaMovieItem",
      "PopularMediaSeriesItem"
    ],
    "SearchHistoryMutationResponse": [
      "SearchHistoryMutationErrorResponse",
      "SearchHistoryMutationSuccessResponse"
    ],
    "SearchHistoryResultItem": [
      "Clip",
      "Movie",
      "PageReference",
      "Series",
      "SportEvent"
    ],
    "SinglePanelLink": [
      "SinglePanelChannelLink",
      "SinglePanelClipLink",
      "SinglePanelEpisodeLink",
      "SinglePanelMovieLink",
      "SinglePanelPageLink",
      "SinglePanelSeriesLink",
      "SinglePanelSportEventLink"
    ],
    "TableauItem": [
      "TableauSportItem"
    ],
    "ThemePanelLink": [
      "ThemePanelClipLink",
      "ThemePanelEpisodeLink",
      "ThemePanelMovieLink",
      "ThemePanelPageLink",
      "ThemePanelSeriesLink",
      "ThemePanelSportEventLink",
      "ThemePanelUrlsLink"
    ],
    "TierComparePagePanel": [
      "MediaPanel",
      "PackagesCompareSheetPanel",
      "PackagesPanel",
      "SportEventPanel",
      "ThemePanel"
    ],
    "TierComparePageTopPanel": [
      "PackageTopPanel",
      "UpsellPanel"
    ],
    "TierLandingPagePanel": [
      "ChannelPanel",
      "MediaPanel",
      "PackageDetailsPanel",
      "PackagesPanel",
      "PagePanel",
      "SportEventPanel",
      "ThemePanel"
    ],
    "TierLandingPageTopPanel": [
      "PackageTopPanel",
      "UpsellPanel"
    ],
    "TopShelfMediaItem": [
      "TopShelfMediaMovieItem",
      "TopShelfMediaSeriesItem"
    ],
    "UserError": [
      "SearchHistoryMutationErrorResponse"
    ],
    "VoucherValidationItem": [
      "VoucherValidationFailure",
      "VoucherValidationSuccess"
    ]
  }
}
    